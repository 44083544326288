import React, { useState, useEffect } from "react";
import Header from "../../../components/header";
import { KeyboardArrowDown} from "@mui/icons-material";
import ChecklistIcon from "../../../assets/checklist_icon.svg";
import ColouredCheckListIcon from "../../../assets/Vector.svg"
import AddIcon from "../../../assets/Add.svg"
import ModalComponent from "../../../components/Modals/modal";
import {useSelector, useDispatch} from 'react-redux'
import { editPayslip, createPayslip } from "../../../redux/Calculator/calculatorAction";
import cogoToast from "cogo-toast"
import moment from "moment"
import GoBack from "../../../components/goBack";


const EditPayslipPage = () => {

     //redux state management
     const dispatch = useDispatch()
     const { payslipEdit, edit_payslip_success } = useSelector(state => state.payslipCalculator)
  
    //  console.log(payslipEdit)
    const [tab, setTab] = useState("net_payment")
    const [payrollDate, setPayrollDate] = useState(payslipEdit.date_of_entry? moment(payslipEdit.date_of_entry).format('YYYY-MM-DD'): new Date())
    const [grossPayable, setGrossPayable] = useState(payslipEdit.gross_salary.gross_payable? payslipEdit.gross_salary.gross_payable : 0)
    const [basicSalary, setBasicSalary] = useState(payslipEdit.gross_salary.basic_salary? payslipEdit.gross_salary.basic_salary: 0);
    const [taxableIncome, setTaxableIncome] = useState(payslipEdit.gross_salary.taxable_income? payslipEdit.gross_salary.taxable_income: 0);
    const [housingAllowance, setHousingAllowance] = useState(payslipEdit.gross_salary.housing_allowance?  payslipEdit.gross_salary.housing_allowance: 0);
    const [transportAllowance, setTransportAllowance] = useState(payslipEdit.gross_salary.transport_allowance? payslipEdit.gross_salary.transport_allowance: 0);
    const [leaveAllowance, setLeaveAllowance] = useState(payslipEdit.gross_salary.leave_allowance? payslipEdit.gross_salary.leave_allowance : 0);
    const [utilityAllowance, setUtilityAllowance] = useState(payslipEdit.gross_salary.utility_allowance? payslipEdit.gross_salary.utility_allowance: 0);
    const [trainingAllowance, setTrainingAllowance] = useState(payslipEdit.gross_salary.training_allowance? payslipEdit.gross_salary.training_allowance : 0);
    const [entertainmentAllowance, setEntertainmentAllowance] = useState(payslipEdit.gross_salary.entertainment_allowance? payslipEdit.gross_salary.entertainment_allowance : 0);
    const [payeAmount, setPayeAmount] = useState(payslipEdit.gross_salary_deductions && payslipEdit.gross_salary_deductions.paye? payslipEdit.gross_salary_deductions.paye : 0);
    const [hmo, setHmo] = useState(payslipEdit.gross_salary_deductions && payslipEdit.gross_salary_deductions.hmo ? payslipEdit.gross_salary_deductions.hmo : 0);
    const [selectEmployee, setSelectedEmployee] = useState(`${payslipEdit.employee.first_name} ${payslipEdit.employee.last_name}`)
    const [employeeId] = useState(`${payslipEdit.employee.id}`)

  
    //deductions from taxable income
    const [employeePension, setEmployeePension] = useState(payslipEdit.gross_salary_deductions && payslipEdit.gross_salary_deductions.employee_pension ? payslipEdit.gross_salary_deductions.employee_pension : 0);
    const [contribution, setContribution] = useState(payslipEdit.gross_salary_deductions && payslipEdit.gross_salary_deductions.employee_contribution? payslipEdit.gross_salary_deductions.employee_contribution : 0);
    const [totalDeductions, setTotalDeductions] = useState(payslipEdit.gross_salary_deductions && payslipEdit.gross_salary_deductions.total_deductions? payslipEdit.gross_salary_deductions.total_deductions : 0);

    //extra deductions
    const [salaryAdvance, setSalaryAdvance] = useState(payslipEdit.gross_salary_deductions && Object.keys(payslipEdit.gross_salary_deductions.more_deductions).length !== 0 && payslipEdit.gross_salary_deductions.others.salary_advance ? payslipEdit.gross_salary_deductions.others.salary_advance : 0)
    const [loanRepayment, setLoanRepayment] = useState(payslipEdit.gross_salary_deductions && Object.keys(payslipEdit.gross_salary_deductions.more_deductions).length !== 0 && payslipEdit.gross_salary_deductions.others.loan_repayment ? payslipEdit.gross_salary_deductions.others.loan_repayment : 0)
  
    //additions to gross payable 
    const [overtime, setOvertime] = useState(payslipEdit.gross_salary_deductions && Object.keys(payslipEdit.gross_salary_deductions.others).length !== 0 && payslipEdit.gross_salary_deductions.others.overtime ? payslipEdit.gross_salary_deductions.others.overtime: 0);
    const [arrears, setArrears] = useState(payslipEdit.gross_salary_deductions && Object.keys(payslipEdit.gross_salary_deductions.others).length !== 0 && payslipEdit.gross_salary_deductions.others.arrears ? payslipEdit.gross_salary_deductions.others.arrears: 0);
    const [bonus, setBonus] = useState(payslipEdit.gross_salary_deductions && Object.keys(payslipEdit.gross_salary_deductions.others).length !== 0 && payslipEdit.gross_salary_deductions.others.bonus ? payslipEdit.gross_salary_deductions.others.bonus: 0);
  
    //net salary
    const [netSalary, setNetSalary] = useState(payslipEdit.net_salary.amount? payslipEdit.net_salary.amount : 0);

    //
    const [allowance, setAllowance] = useState(payslipEdit.allowance && Object.keys(payslipEdit.allowance).length !== 0 && payslipEdit.allowance.allowance_payable? payslipEdit.allowance.allowance_payable : 0); 
    //employees drop down
  
  let basic;
    //payslip calculations functionalit
    const handleBasicSalaryBreakdown = (e) => {
      setGrossPayable(e.target.value)
      basic = Number(e.target.value)
      setBasicSalary(0.20 * basic)
      setTaxableIncome(0.35 * basic)
      //allowances
      setHousingAllowance(0.10 * basic)
      setTransportAllowance(0.05 * basic)
      setLeaveAllowance(0.20 * basic)
      setUtilityAllowance(0.20 * basic)
      setTrainingAllowance(0.10 * basic)
      setEntertainmentAllowance(0.15 * basic)

      //employee pension calculation
      let empPension = 0.08 * 0.35 * basic;
      //employee pension calculation
       basic > 30000 ?
        setEmployeePension(empPension) : setEmployeePension(0)
     
      
  
      //PAYE calculation
      let personalIncomeTax;
      let annualTaxableIncome;
      let remainingAmount;
      let paye;
      if (basic > 30000) {
        annualTaxableIncome = 0.35 * basic * 12;
        if (annualTaxableIncome <= 300000) {
          personalIncomeTax = 0.07;
          let firstStep = personalIncomeTax * 300000;
          paye = firstStep / 12;
          setPayeAmount(Number(paye.toFixed(2)));
          setTotalDeductions(
            paye +
            empPension +
            Number(salaryAdvance) +
            Number(loanRepayment) +
            Number(hmo) +
            Number(contribution)
          );
        } else if (
          annualTaxableIncome > 300000 &&
          annualTaxableIncome <= 600000
        ) {
          personalIncomeTax = 0.11;
          remainingAmount = annualTaxableIncome - 300000;
          let secondStep = 0.07 * 300000 + personalIncomeTax * remainingAmount;
          paye = secondStep / 12;
          setPayeAmount(Number(paye.toFixed(2)));
          setTotalDeductions(
            paye +
            empPension +
            Number(salaryAdvance) +
            Number(loanRepayment) +
            Number(hmo) +
            Number(contribution)
          );
        } else if (
          annualTaxableIncome > 600000 &&
          annualTaxableIncome <= 1110000
        ) {
          personalIncomeTax = 0.15;
          remainingAmount = annualTaxableIncome - 600000;
          let thirdStep = 0.07 * 300000 + 0.11 * 300000 + 0.15 * remainingAmount;
          paye = thirdStep / 12;
          setPayeAmount(Number(paye.toFixed(2)));
          setTotalDeductions(
            paye +
            empPension +
            Number(salaryAdvance) +
            Number(loanRepayment) +
            Number(hmo) +
            Number(contribution)
          );
        } else if (
          annualTaxableIncome > 1100000 &&
          annualTaxableIncome <= 1600000
        ) {
          personalIncomeTax = 0.19;
          remainingAmount = annualTaxableIncome - 1100000;
          let fourthStep =
            0.07 * 300000 +
            0.11 * 300000 +
            0.15 * 500000 +
            personalIncomeTax * remainingAmount;
          paye = fourthStep / 12;
          setPayeAmount(Number(paye.toFixed(2)));
          setTotalDeductions(
            paye +
            empPension +
            Number(salaryAdvance) +
            Number(loanRepayment) +
            Number(hmo) +
            Number(contribution)
          );
        } else if (
          annualTaxableIncome > 1600000 &&
          annualTaxableIncome <= 3200000
        ) {
          personalIncomeTax = 0.21;
          remainingAmount = annualTaxableIncome - 1600000;
          let fifthStep =
            0.07 * 300000 +
            0.11 * 300000 +
            0.15 * 500000 +
            0.19 * 500000 +
            personalIncomeTax * remainingAmount;
          paye = fifthStep / 12;
          setPayeAmount(Number(paye.toFixed(2)));
          setTotalDeductions(
            paye +
            empPension +
            Number(salaryAdvance) +
            Number(loanRepayment) +
            Number(hmo) +
            Number(contribution)
          );
        } else if (annualTaxableIncome > 3200000) {
          personalIncomeTax = 0.24;
          remainingAmount = annualTaxableIncome - 3200000;
          let sixthStep =
            0.07 * 300000 +
            0.11 * 300000 +
            0.15 * 500000 +
            0.19 * 500000 +
            0.21 * 1600000 +
            personalIncomeTax * remainingAmount;
          paye = sixthStep / 12;
          setPayeAmount(Number(paye.toFixed(2)));
          setTotalDeductions(
            paye +
            empPension +
            Number(salaryAdvance) +
            Number(loanRepayment) +
            Number(hmo) +
            Number(contribution)
          );
        } else {
          paye = 0;
          setTotalDeductions(
            paye +
            empPension +
            Number(salaryAdvance) +
            Number(loanRepayment) +
            Number(hmo) +
            Number(contribution)
          );
        }
      } else {
        paye = 0;
        setPayeAmount(0);
        setTotalDeductions(paye + empPension + hmo);
      }
  }
  
  useEffect(() => {
    let net;
    if(parseFloat(grossPayable) > 0) {
      net = (Number(grossPayable) + Number(overtime) + Number(arrears) + Number(bonus)) - Number(totalDeductions)
      //console.log(net)
      setNetSalary(net)
    }
  }, [ employeePension, arrears, bonus, totalDeductions, overtime, grossPayable])
   
  const handleHmoInput = (e) => {
    setHmo(e.target.value);
    let hmoAmount = parseFloat(e.target.value);

    let deduction;
    if (hmoAmount === "" || isNaN(hmoAmount)) {
      deduction =
        payeAmount +
        employeePension +
        0 +
        Number(salaryAdvance) +
        Number(loanRepayment) +
        Number(contribution);
    } else {
      deduction =
        payeAmount +
        employeePension +
        hmoAmount +
        Number(salaryAdvance) +
        Number(loanRepayment) +
        Number(contribution);
    }

    setTotalDeductions(Number(deduction).toFixed(2));
  };
    
    //functionalty for add deductions modal
    const [addMore, setAddMore] = useState(
      false
    )
  
    const toggleAddMoreModal = () => {
      setAddMore(!addMore)
  }
  const [showMoreDeduct, setShowMoreDeduct] = useState(false)
  
  const toggleShowMoreDeduct = () => {
    // check for loan repayment and salary advance if it is greater than zero

    setShowMoreDeduct(true);
    setAddMore(false);

    let deductionPlus;
    // add all values
    deductionPlus =
      Number(payeAmount) +
      Number(employeePension) +
      Number(hmo) +
      Number(salaryAdvance) +
      Number(loanRepayment) +
      Number(contribution);
    setTotalDeductions(deductionPlus);
  };

  const handleSalaryAdvance = (e) => {
    setSalaryAdvance(e.target.value);
    let advanceAmount = parseFloat(e.target.value);

    let deduction;
    if (advanceAmount === "" || isNaN(advanceAmount)) {
      deduction =
        Number(payeAmount) +
        Number(employeePension) +
        Number(hmo) +
        0 +
        Number(loanRepayment) +
        Number(contribution);
    } else {
      deduction =
        payeAmount +
        Number(employeePension) +
        Number(hmo) +
        Number(advanceAmount) +
        Number(loanRepayment) +
        Number(contribution);
    }

    setTotalDeductions(Number(deduction).toFixed(2));
  };

  const handleLoanRepayment = (e) => {
    setLoanRepayment(e.target.value);
    let repay = parseFloat(e.target.value);

    let deduction;
    if (repay === "" || isNaN(repay)) {
      deduction =
        Number(payeAmount) +
        Number(employeePension) +
        Number(hmo) +
        0 +
        Number(salaryAdvance) +
        Number(contribution);
    } else {
      deduction =
        Number(payeAmount) +
        Number(employeePension) +
        Number(hmo) +
        Number(repay) +
        Number(salaryAdvance) +
        Number(contribution);
    }

    setTotalDeductions(Number(deduction).toFixed(2));
  };
  
  const handleContribution = (e) => {
    setContribution(e.target.value);
    let contri = parseFloat(e.target.value);

    let deduction;
    if (contri === "" || isNaN(contri)) {
      deduction =
        Number(payeAmount) +
        Number(employeePension) +
        Number(hmo) +
        0 +
        Number(salaryAdvance) +
        Number(loanRepayment);
    } else {
      deduction =
        Number(payeAmount) +
        Number(employeePension) +
        Number(hmo) +
        Number(contri) +
        Number(salaryAdvance) +
        Number(loanRepayment);
    }
    setTotalDeductions(Number(deduction).toFixed(2));
  };
 
  const ref = payslipEdit.payroll_reference
  // console.log(ref)

  const handleCreatePayslip = () => {
    //read the input fields and ensure they are not empty
    if (grossPayable > 0 && allowance > 0) {
      let response = {
        generated: true,
        employees: [payslipEdit?.employee?.id],
        date_of_entry: payrollDate,
        net_payment: {
          gross_salary_breakdown: {
            gross_payable: Number(grossPayable),
            taxable_income: taxableIncome,
            basic_salary: parseFloat(Number(basicSalary)),
            housing_allowance: housingAllowance,
            transport_allowance: transportAllowance,
            leave_allowance: leaveAllowance,
            utility_allowance: utilityAllowance,
            training_allowance: trainingAllowance,
            entertainment_allowance: entertainmentAllowance,
          },
          deductions: {
            paye: payeAmount,
            employee_pension: Number(employeePension.toFixed(2)),
            hmo: Number(hmo),
            contribution: Number(contribution),
            total_deductions: Number(parseFloat(totalDeductions).toFixed(2))
          },
          other_deductions: {
            salary_advance: Number(salaryAdvance),
            loan_repayment: Number(loanRepayment),
          },
          others: {
            arrears: Number(arrears),
            bonus: Number(bonus),
          },
          net_salary: Number(netSalary.toFixed(2)),
        },
        allowance: {
          allowance_payable: Number(parseFloat(allowance).toFixed(2)),
        },
      };
      // console.log(response)
      dispatch(editPayslip({response, ref}));
    } else if (allowance > 0 && grossPayable === 0 ) {
      let response = {
        generated: true,
        employees: [payslipEdit?.employee?.id],
        date_of_entry: payrollDate,
        allowance: {
          allowance_payable: Number(parseFloat(allowance).toFixed(2)),
        },
      }
      // console.log(response)
      dispatch(editPayslip({response, ref}));
    } else if((allowance === 0 || allowance === "" ||  allowance === isNaN) && grossPayable > 0) {
      let response = {
        generated: true,
        employees: [[payslipEdit?.employee?.id]],
        date_of_entry: payrollDate,
        net_payment: {
          gross_salary_breakdown: {
            gross_payable: Number(grossPayable),
            taxable_income: taxableIncome,
            basic_salary: parseFloat(Number(basicSalary)),
            housing_allowance: housingAllowance,
            transport_allowance: transportAllowance,
            leave_allowance: leaveAllowance,
            utility_allowance: utilityAllowance,
            training_allowance: trainingAllowance,
            entertainment_allowance: entertainmentAllowance,
          },
         //taxable: isTaxable,
          deductions: {
            paye: payeAmount,
            employee_pension: Number(employeePension.toFixed(2)),
            hmo: Number(hmo),
            contribution: Number(contribution),
            total_deductions: Number(parseFloat(totalDeductions).toFixed(2))
          },
          other_deductions: {
            salary_advance: Number(salaryAdvance),
            loan_repayment: Number(loanRepayment),
          },
          others: {
            arrears: Number(arrears),
            bonus: Number(bonus),
          },
          net_salary: Number(netSalary.toFixed(2)),
        }
    }
    // console.log(response)
    dispatch(editPayslip({response, ref}));
   } else {
      cogoToast.error(
        `Cannot generate payslip, please confirm that the date of payroll, gross payable and employees field are filled!`
      );
    }
    setGeneratePayslip(false);
  };
  
  //reset all the values after successfully editing a payslip
  useEffect(() => {
    if(edit_payslip_success) {
      setPayrollDate(new Date())
      setGrossPayable(0);
      setBasicSalary(0);
      setTaxableIncome(0);
      setHousingAllowance(0);
      setTransportAllowance(0);
      setTrainingAllowance(0);
      setLeaveAllowance(0);
      setUtilityAllowance(0);
      setEntertainmentAllowance(0);
      setPayeAmount(0);
      setHmo(0);
      setSelectedEmployee([])
      setEmployeePension(0);
      setTotalDeductions(0)
      setContribution(0);
      setSalaryAdvance(0)
      setLoanRepayment(0)
      setOvertime(0)
      setArrears(0)
      setBonus(0)
      setNetSalary(0)
      setAllowance(0)
    }
  }, [edit_payslip_success])

    //functionality for add others modal
    const [showMore, setShowMore] = useState(false)
  
  const toggleShowMore = () => {
        setShowMore(!showMore)
        setAddOthers(false)
  }
    const [addOthers, setAddOthers] = useState(
      false
    )
  
  const toggleAddOthersModal = () => {
      setAddOthers(!addOthers)
  }
  //functionality of generate payslip 
  const [generatePayslip, setGeneratePayslip] = useState(false)
  
   const toggleGeneratePayslipModal = () => {
     setGeneratePayslip(!generatePayslip)
    }

    //functionality for save payslip
    const [savePayslip, setSavePayslip] = useState(false)

    const toggleSavePayslipModal = () => {
      setSavePayslip(!savePayslip)
    }

    const handleSavePayslip = () => {
      //let isTaxable = grossPayable > 31000 ? true : false;
  
      //read the input fields and ensure they are not empty
      if (grossPayable > 0 && allowance > 0) {
        let response = {
          generated: false,
          employees: [[payslipEdit?.employee?.id]],
          date_of_entry: payrollDate,
          net_payment: {
            gross_salary_breakdown: {
              gross_payable: Number(grossPayable),
              taxable_income: taxableIncome,
              basic_salary: basicSalary,
              housing_allowance: housingAllowance,
              transport_allowance: transportAllowance,
              leave_allowance: leaveAllowance,
              utility_allowance: utilityAllowance,
              training_allowance: trainingAllowance,
              entertainment_allowance: entertainmentAllowance,
            },
           //taxable: isTaxable,
            deductions: {
              paye: payeAmount,
              employee_pension: Number(employeePension.toFixed(2)),
              hmo: Number(hmo),
              contribution: Number(contribution),
              total_deductions: Number(parseFloat(totalDeductions).toFixed(2)),
            },
            other_deductions: {
              salary_advance: Number(salaryAdvance),
              loan_repayment: Number(loanRepayment),
            },
            others: {
              arrears: Number(arrears),
              bonus: Number(bonus),
            },
            net_salary: Number(netSalary.toFixed(2)),
          },
          allowance: {
            allowance_payable: Number(parseFloat(allowance).toFixed(2)),
          }
        };
        dispatch(createPayslip(response))
      } else if (allowance > 0 && (grossPayable === 0 || grossPayable === "" )) {
        let response = {
          generated: false,
          employees: [[payslipEdit?.employee?.id]],
          date_of_entry: payrollDate,
          allowance: {
            allowance_payable: Number(parseFloat(allowance).toFixed(2)),
          },
        }
        // console.log(response)
        dispatch(createPayslip(response));
      } else if((allowance === 0 || allowance === "" ||  allowance === isNaN) & grossPayable > 0) {
        let response = {
          generated: false,
          employees: [[payslipEdit?.employee?.id]],
          date_of_entry: payrollDate,
          net_payment: {
            gross_salary_breakdown: {
              gross_payable: Number(grossPayable),
              taxable_income: taxableIncome,
              basic_salary: parseFloat(Number(basicSalary)),
              housing_allowance: housingAllowance,
              transport_allowance: transportAllowance,
              leave_allowance: leaveAllowance,
              utility_allowance: utilityAllowance,
              training_allowance: trainingAllowance,
              entertainment_allowance: entertainmentAllowance,
            },
            deductions: {
              paye: payeAmount,
              employee_pension: Number(employeePension.toFixed(2)),
              hmo: Number(hmo),
              contribution: Number(contribution),
              total_deductions: Number(parseFloat(totalDeductions).toFixed(2))
            },
            other_deductions: {
              salary_advance: Number(salaryAdvance),
              loan_repayment: Number(loanRepayment),
            },
            others: {
              arrears: Number(arrears),
              bonus: Number(bonus),
            },
            net_salary: Number(netSalary.toFixed(2)),
          }
      }
      dispatch(createPayslip(response));
     } else {
        cogoToast.error(
          `Cannot save payslip, please confirm that the date of payroll, gross payable and  select employees fields are filled!`
        );
      }
      setSavePayslip(false);
    }; 
    

    //toggle the sections of the calculator
    const [net, setNet] = useState(false)
    const [allow, setAllow] = useState(false)
    return (
      <>
      {/* Modal ComponentOne - Add more deductions*/}
        < ModalComponent
        title='Add More Deductions'
        show={addMore}
        showModal={() => setAddMore(false)}
        >
          <div>
            <div className='flex items-center justify-center gap-6 mt-4'>
              <div>
                <p className='text-secondary text-sm font-normal'>Salary Advance</p>
                <input
                type='text' 
                value = {salaryAdvance}
                onChange={(e) => setSalaryAdvance(e.target.value)}
                placeholder="Enter Amount"
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
                />
              </div>
              <div>
                <p className='text-secondary text-sm font-normal'>Loan Repayment</p>
                <input
                type='text' 
                value = {loanRepayment}
                onChange={(e) => setLoanRepayment(e.target.value)}
                placeholder="Enter Amount"
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
                />
              </div>
            </div>
            <div className="mt-4 flex justify-center align-center items-center ">
                <button 
                onClick={toggleShowMoreDeduct}
                className='outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-[10px] px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]'>Add Selected</button>
            </div>
          </div>
          
        </ModalComponent>
        {/* Modal ComponentTwo - Add bonus*/}
        < ModalComponent
        title='Add More Deductions'
        show={addOthers}
        showModal={() => setAddOthers(false)}
        >
          <div>
            <div className='flex items-center justify-center gap-6 mt-4'>
              <div>
                <p className='text-secondary text-sm font-normal'>Bonus</p>
                <input
                type='text' 
                value= {bonus}
                onChange= {(e) => setBonus(e.target.value)}
                placeholder="Enter Amount"
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
                />
              </div>
            </div>
            <div className="mt-4 flex justify-center align-center items-center ">
                <button 
                onClick={toggleShowMore}
                className='outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-[10px] px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]'>Add Selected</button>
            </div>
          </div>        
        </ModalComponent>
        {/* Modal to confirm creation of payslip */}
        <ModalComponent
        title={`Generate Payslip`}
        show={generatePayslip}
        showModal={() => setGeneratePayslip(false)}
        subtitle={`Are you sure you want to generate payslip & allowance for ${selectEmployee}`}
      >
        <div className="">
          <p className="text-[#96A397] text-xs font-medium mt">Please review the details below and click on proceed</p>
          <div classsName="mt-1 flex-col justify-start items-start gap-2 inline-flex">
            <div className="mt-2 items-center justify-between flex">
              <p
                className={
                  payrollDate === ""
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Payroll Date
              </p>
              <img
                src={
                  payrollDate !== ""
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  employeeId > 0 && employeeId !== 0 
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Employee Name
              </p>
              <img
                src={
                  employeeId > 0 && employeeId !== 0 
                    ? ChecklistIcon
                    : ColouredCheckListIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  grossPayable > 0 &&
                    grossPayable !== 0 &&
                    grossPayable !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Gross Pay
              </p>
              <img
                src={
                  grossPayable > 0 &&
                    grossPayable !== 0 &&
                    grossPayable !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  totalDeductions > 0 &&
                    totalDeductions !== 0 &&
                    totalDeductions !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Deductions
              </p>
              <img
                src={
                  totalDeductions > 0 &&
                    totalDeductions !== 0 &&
                    totalDeductions !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  overtime > 0 && overtime !== 0 && overtime !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Overtime
              </p>
              <img
                src={
                  overtime > 0 && overtime !== 0 && overtime !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  arrears > 0 && arrears !== 0 && arrears !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Arrears
              </p>
              <img
                src={
                  arrears > 0 && arrears !== 0 && arrears !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  hmo > 0 && hmo !== 0 && hmo !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                HMO
              </p>
              <img
                src={
                  hmo > 0 && hmo !== 0 && hmo !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  allowance > 0 && allowance !== 0 && allowance !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Allowance
              </p>
              <img
                src={
                  allowance > 0 && allowance !== 0 && allowance !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
          </div>
        </div>
        <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
          <button
            onClick={() => setGeneratePayslip(false)}
            className="text-sm text-medium leading-5 text-[#5C715E]"
          >
            Cancel
          </button>
          <button
            onClick={handleCreatePayslip}
            className="outline-0 bg-primary px-5 py-2.5 rounded-[8px] h-[40px] text-sm text-medium leading-5 text-white"
          >
            Proceed
          </button>
        </div>
      </ModalComponent>
              {/* Modal to confirm creation of draft payslip */}
              <ModalComponent
        title={`Save Payslip`}
        show={savePayslip}
        showModal={() => setSavePayslip(false)}
        subtitle={`Are you sure you sure you want to create draft payslips & allowance for ${selectEmployee}?`
        }
      >
         <div className="">
          <p className="text-[#96A397] text-xs font-medium mt">Please review the details below and click on save for later</p>
          <div classsName="mt-1 flex-col justify-start items-start gap-2 inline-flex">
            <div className="mt-2 items-center justify-between flex">
              <p
                className={
                  payrollDate === ""
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Payroll Date
              </p>
              <img
                src={
                  payrollDate !== ""
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  employeeId > 0
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Employee Name
              </p>
              <img
                src={
                  employeeId > 0
                    ? ChecklistIcon
                    : ColouredCheckListIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  grossPayable > 0 &&
                    grossPayable !== 0 &&
                    grossPayable !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Gross Pay
              </p>
              <img
                src={
                  grossPayable > 0 &&
                    grossPayable !== 0 &&
                    grossPayable !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  totalDeductions > 0 &&
                    totalDeductions !== 0 &&
                    totalDeductions !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Deductions
              </p>
              <img
                src={
                  totalDeductions > 0 &&
                    totalDeductions !== 0 &&
                    totalDeductions !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  overtime > 0 && overtime !== 0 && overtime !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Overtime
              </p>
              <img
                src={
                  overtime > 0 && overtime !== 0 && overtime !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  arrears > 0 && arrears !== 0 && arrears !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Arrears
              </p>
              <img
                src={
                  arrears > 0 && arrears !== 0 && arrears !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  hmo > 0 && hmo !== 0 && hmo !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                HMO
              </p>
              <img
                src={
                  hmo > 0 && hmo !== 0 && hmo !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  allowance > 0 && allowance !== 0 && allowance !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Allowance
              </p>
              <img
                src={
                  allowance > 0 && allowance !== 0 && allowance !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
          </div>
        </div>
        <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
          <button
            className="text-sm text-medium leading-5 text-[#5C715E]"
            onClick={() => setSavePayslip(false)}
          >
            Cancel
          </button>
          <button
            onClick={handleSavePayslip}
            disabled={
              selectEmployee &&
                grossPayable === 0 &&
                payrollDate === ""
                ? true
                : false
            }
            className="outline-0 bg-primary rounded-[8px] px-5 py-2.5 h-[40px] text-sm text-medium leading-5 text-white"
          >
            Save for later
          </button>
        </div>
      </ModalComponent>
      <GoBack/>
        <Header
          text="Edit Payslip"
          tabs={
            <div className="flex gap-[16px] mt-[16px]">
              <p
                className={
                  tab === "net_payment"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("net_payment")}
              >
                Net Payment
              </p>
            </div>
          }
        ></Header>
       {tab === "net_payment" && (
        <>
          <div className="rounded-lg mt-4">
            <div className="flex gap-6 w-full">
              <div className="w-[68%]">
                <div className="bg-white rounded-lg px-5 py-[27px]">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Payroll Period
                    </p>
                  </div>
                  <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                    <p>Payroll Date</p>
                    <input
                      type="date"
                      required
                      disabled={true}
                      name="payrollDate"
                      placeholder="mm/dd/yyyy - mm/dd/yyyy"
                      value={payrollDate}
                      onChange={(e) => setPayrollDate(e.target.value)}
                      className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-1/2 font-medium text-sm mb-[24px]"
                    />
                  </div>
                </div>
                <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Employee Name
                    </p>
                  </div>
                  <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Employee
                    </label>
                    <input
                      type="select"
                      required
                      disabled={true}
                      value={selectEmployee} 
                      name="selectEmployee"
                      placeholder="Select Employee"
                      className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-1/2 font-medium text-sm mb-[24px]"
                    /> 
                  </div>
                </div>
                <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Net Payment
                    </p>
                    <KeyboardArrowDown
                      onClick={()=> setNet(!net)}
                      style={{ color: "#5C715E", fontSize: "14px" }}
                    />
                  </div>
                </div>
                {!net && <div>
                <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Gross Salary Breakdown
                    </p>
                  </div>
                  <div className="mt-4 flex gap-6 w-full">
                    <div className="">
                      <p className="text-seconday font-normal text-[14px] leading-[18px]">
                        Gross Payable
                      </p>
                      <input
                        type="text"
                        name="grossPayable"
                        required
                        value={grossPayable}
                        onChange={handleBasicSalaryBreakdown}
                        placeholder="Enter Amount"
                        className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-[224px] font-medium text-sm mb-[24px]"
                      />
                    </div>
                    <div className="">
                      <p className="text-seconday font-normal text-[14px] leading-[18px]">
                        Taxable Income
                      </p>
                      <input
                        type="text"
                        name="taxableIncome"
                        disabled
                        value={Number(taxableIncome).toFixed(2)}
                        placeholder="Enter Amount"
                        className="text-[#96A397] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-[224px] font-medium text-xs mb-[24px]"
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex gap-4 w-full">
                    <div>
                      <p className="text-seconday font-normal text-[14px]">
                        Basic Salary (20%)
                      </p>
                      <input
                        type="text"
                        name="basic_salary"
                        disabled
                        value={Number(basicSalary).toFixed(2)}
                        placeholder="Enter Amount"
                        className="text-[#96A397] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                    <div>
                      <p className="text-seconday font-normal text-[14px]">
                        Housing Allowance (10%)
                      </p>
                      <input
                        type="text"
                        name="housing_allowance"
                        disabled
                        value={Number(housingAllowance).toFixed(2)}
                        placeholder="Enter Amount"
                        className="text-[#96A397] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                    <div>
                      <p className="text-seconday font-normal text-[14px]">
                        Transport Allowance (5%)
                      </p>
                      <input
                        type="text"
                        name="transport_allowance"
                        disabled
                        value={Number(transportAllowance).toFixed(2)}
                        placeholder="Enter Amount"
                        className="text-[#96A397] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex gap-4 w-full">
                    <div>
                      <p className="text-seconday font-normal text-[14px]">
                        Leave Allowance (20%)
                      </p>
                      <input
                        type="text"
                        name="leave_allowance"
                        disabled
                        value={Number(leaveAllowance).toFixed(2)}
                        placeholder="Enter Amount"
                        className="text-[#ABB6AC] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                    <div>
                      <p className="text-seconday font-normal text-[14px]">
                        Utility Allowance (20%)
                      </p>
                      <input
                        type="text"
                        name="utility_allowance"
                        disabled
                        value={Number(utilityAllowance).toFixed(2)}
                        placeholder="Enter Amount"
                        className="text-[#ABB6AC] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                    <div>
                      <p className="text-seconday font-normal text-[14px]">
                        Training Allowance (5%)
                      </p>
                      <input
                        type="text"
                        name="training_allowance"
                        disabled
                        value={Number(trainingAllowance).toFixed(2)}
                        placeholder="Enter Amount"
                        className="text-[#ABB6AC] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                    <div>
                      <p className="text-seconday font-normal text-[14px]">
                        Entertainment Allowance (15%)
                      </p>
                      <input
                        type="text"
                        name="etertainment_allowance"
                        disabled
                        value={Number(entertainmentAllowance).toFixed(2)}
                        placeholder="Enter Amount"
                        className="text-[#ABB6AC] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <div className="w-full flex gap-2">
                      <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                        Deductions
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 flex gap-4 w-full">
                    <div>
                      <p className="text-seconday font-normal text-[14px]">
                        PAYE
                      </p>
                      <input
                        type="text"
                        name="paye"
                        disabled
                        value={Number(payeAmount).toFixed(2)}
                        // onChange={(e) => setPayeAmount(e.target.value)}
                        placeholder="Enter Amount"
                        className="text-[#ABB6AC] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                    <div>
                      <p className="text-seconday font-normal text-[14px]">
                        Employee Pension
                      </p>
                      <input
                        type="text"
                        name="employee_pension"
                        value={Number(employeePension).toFixed(2)}
                        disabled
                        placeholder="Amount"
                        className="text-[#ABB6AC] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                    <div>
                      <p className="text-green-800 font-normal text-[14px]">
                        HMO
                      </p>
                      <input
                        type="text"
                        name="overtime"
                        value={hmo}
                        onChange={handleHmoInput}
                        placeholder="Enter Amount"
                        className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>
                    <div>
                      <p className="text-green-800 font-normal text-[14px]">
                        Contribution
                      </p>
                      <input
                        type="text"
                        name="contribution"
                        value={contribution}
                        onChange={handleContribution}
                        placeholder="Enter Amount"
                        className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                      />
                    </div>

                    {showMoreDeduct && (
                      <div>
                        <p className="text-seconday font-normal text-[14px]">
                          Salary Advance
                        </p>
                        <input
                          type="text"
                          name="salary_advance"
                          value={salaryAdvance}
                          onChange={handleSalaryAdvance}
                          placeholder="Enter Amount"
                          className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                        />
                      </div>
                    )}
                    {showMoreDeduct && (
                      <div>
                        <p className="text-seconday font-normal text-[14px]">
                          Loan Repayment
                        </p>
                        <input
                          type="text"
                          name="loan_repayment"
                          value={loanRepayment}
                          onChange={handleLoanRepayment}
                          placeholder="Enter Amount"
                          className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-[105px] font-medium text-[14px] mb-[24px]"
                        />
                      </div>
                    )}
                  </div>
                  <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                    <p>Total Deductions</p>
                    <input
                      type="select"
                      name="totalDeductions"
                      value={Number(totalDeductions).toFixed(2)}
                      disabled={true}
                      placeholder="Amount"
                      className="text-[#ABB6AC] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#ABB6AC] mt-[4px] w-1/2 font-medium text-sm mb-[24px]"
                    />
                  </div>
                  <div className="mt-4 " onClick={toggleAddMoreModal}>
                    <button className="flex items-center justify-center gap-1 outline-none bg-transparent text-secondary1 text-sm font-medium py-[10px] px-5">
                      <img src={AddIcon} alt="add_icon" />
                      <p className="text-[#96A397] font-medium text-sm ">
                        Add More
                      </p>
                    </button>
                  </div>
                </div>
                <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Others
                    </p>
                  </div>
                  <div className="mt-4 flex gap-4 w-full">
                    <div className="">
                      <p className="text-seconday font-normal text-[14px] leading-[18px]">
                        Overtime
                      </p>
                      <input
                        type="text"
                        name="overtime"
                        value={overtime}
                        onChange={(e) => setOvertime(e.target.value)}
                        placeholder="Enter Amount"
                        className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm mb-[24px]"
                      />
                    </div>
                    <div className="">
                      <p className="text-seconday font-normal text-[14px] leading-[18px]">
                        Arrears
                      </p>
                      <input
                        type="text"
                        name="arrears"
                        value={arrears}
                        onChange={(e) => setArrears(e.target.value)}
                        placeholder="Enter Amount"
                        className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm mb-[24px]"
                      />
                    </div>
                    {showMore && (
                      <div className="">
                        <p className="text-seconday font-normal text-[14px] leading-[18px]">
                          Bonus
                        </p>
                        <input
                          type="text"
                          name="bonus"
                          value={bonus}
                          onChange={(e) => setBonus(e.target.value)}
                          placeholder="Enter Amount"
                          className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm mb-[24px]"
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <button
                      className="flex items-center justify-center gap-1 outline-none bg-transparent text-secondary1 text-sm font-medium py-[10px] px-5"
                      onClick={toggleAddOthersModal}
                    >
                      <img src={AddIcon} alt="add_icon" />
                      <p className="text-[#96A397] font-medium text-sm ">
                        Add More
                      </p>
                    </button>
                  </div>
                </div>
                <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Net Salary
                    </p>
                  </div>
                  <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                    <p>Net Salary</p>
                    <input
                      type="text"
                      required={true}
                      name="net_salary"
                      disabled={true}
                      value={Number(netSalary).toFixed(2)}
                      placeholder="Net Salary"
                      className="text-[#96A397] p-3 outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px] w-1/2 font-medium text-sm mb-[24px]"
                    />
                  </div>
                </div>
                </div>}
                <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Allowance
                    </p>
                    <KeyboardArrowDown
                      onClick={()=> setAllow(!allow)}
                      style={{ color: "#5C715E", fontSize: "14px" }}
                    />
                  </div>
                </div>
                {!allow && <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Allowance
                    </p>
                  </div>
                  <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                    <p>Allowance Payable</p>
                    <input
                      type="text"
                      required={true}
                      name="allowancePayable"
                      value={allowance}
                      onChange={(e) => setAllowance(e.target.value)}
                      placeholder="Allowance"
                      className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-1/2 font-medium text-sm mb-[24px]"
                    />
                  </div>
                </div> }

                <div className="flex justify-end mt-6">             
                  <div className="flex gap-6">
                  <button
                      onClick={toggleSavePayslipModal}
                      className="outline-none bg-transparent text-secondary1 text-sm font-medium py-[10px] px-5"
                    >
                      Save for later
                    </button>
                    <button
                      className="outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-[10px] px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
                      onClick={toggleGeneratePayslipModal}
                    >
                      Generate Payslip
                    </button>
                  </div>
                </div>
              </div>
              {/* the checklist side menu */}

              <div className="w-[32%] rounded-lg">
                <div className="bg-white rounded-lg py-4 px-4">
                  <h6 className="text-tertiary text-sm font-semibold">
                    Current Setting
                  </h6>
                  <p className="text-[#5C715E] text-xs">
                    To see your payslip estimates, enter the following settings:
                  </p>
                  <div className="mt-5 flex gap-2 items-center">
                    <p
                      className={
                        payrollDate === ""
                          ? "text-[#ABB6AC] text-sm font-medium"
                          : "text-secondary text-sm font-medium"
                      }
                    >
                      Payroll Date
                    </p>
                    <img
                      src={
                        payrollDate !== ""
                          ? ColouredCheckListIcon
                          : ChecklistIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                  <div className="mt-2 flex gap-2 items-center">
                    <p
                      className={
                        employeeId !== 0
                          ? "text-[#ABB6AC] text-sm font-medium"
                          : "text-secondary text-sm font-medium"
                      }
                    >
                      Employee Name
                    </p>
                    <img
                      src={
                        employeeId !== 0 
                          ? ChecklistIcon
                          : ColouredCheckListIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                  <div className="mt-2 flex gap-2 items-center">
                    <p
                      className={
                        grossPayable > 0 &&
                          grossPayable !== 0 &&
                          grossPayable !== isNaN
                          ? "text-secondary text-sm font-medium"
                          : "text-[#ABB6AC] text-sm font-medium"
                      }
                    >
                      Gross Pay
                    </p>
                    <img
                      src={
                        grossPayable > 0 &&
                          grossPayable !== 0 &&
                          grossPayable !== isNaN
                          ? ColouredCheckListIcon
                          : ChecklistIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                  <div className="mt-2 flex gap-2 items-center">
                    <p
                      className={
                        totalDeductions > 0 &&
                          totalDeductions !== 0 &&
                          totalDeductions !== isNaN
                          ? "text-secondary text-sm font-medium"
                          : "text-[#ABB6AC] text-sm font-medium"
                      }
                    >
                      Deductions
                    </p>
                    <img
                      src={
                        totalDeductions > 0 &&
                          totalDeductions !== 0 &&
                          totalDeductions !== isNaN
                          ? ColouredCheckListIcon
                          : ChecklistIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                  <div className="mt-2 flex gap-2 items-center">
                    <p
                      className={
                        overtime > 0 && overtime !== 0 && overtime !== isNaN
                          ? "text-secondary text-sm font-medium"
                          : "text-[#ABB6AC] text-sm font-medium"
                      }
                    >
                      Overtime
                    </p>
                    <img
                      src={
                        overtime > 0 && overtime !== 0 && overtime !== isNaN
                          ? ColouredCheckListIcon
                          : ChecklistIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                  <div className="mt-2 flex gap-2 items-center">
                    <p
                      className={
                        arrears > 0 && arrears !== 0 && arrears !== isNaN
                          ? "text-secondary text-sm font-medium"
                          : "text-[#ABB6AC] text-sm font-medium"
                      }
                    >
                      Arrears
                    </p>
                    <img
                      src={
                        arrears > 0 && arrears !== 0 && arrears !== isNaN
                          ? ColouredCheckListIcon
                          : ChecklistIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                  <div className="mt-2 flex gap-2 items-center">
                    <p
                      className={
                        hmo > 0 && hmo !== 0 && hmo !== isNaN
                          ? "text-secondary text-sm font-medium"
                          : "text-[#ABB6AC] text-sm font-medium"
                      }
                    >
                      HMO
                    </p>
                    <img
                      src={
                        hmo > 0 && hmo !== 0 && hmo !== isNaN
                          ? ColouredCheckListIcon
                          : ChecklistIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                  <div className="mt-2 flex gap-2 items-center">
                    <p
                      className={
                        allowance > 0 && allowance !== 0 && allowance !== isNaN
                          ? "text-secondary text-sm font-medium"
                          : "text-[#ABB6AC] text-sm font-medium"
                      }
                    >
                      Allowance
                    </p>
                    <img
                      src={
                       allowance > 0 && allowance !== 0 && allowance !== isNaN
                          ? ColouredCheckListIcon
                          : ChecklistIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
       
      </>
    )
  };


export default EditPayslipPage;